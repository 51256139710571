import { inject, Injectable } from '@angular/core';
import { GrpcHandler, GrpcInterceptor } from '@ngx-grpc/core';
import { GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { isMockAuth } from '@camino-solutions/core/environment';
import { isNotEmptyString } from '@camino-solutions/utils/typeguard';

// import { environment } from '@camino-solutions/core/environment';

@Injectable()
export class GrpcAuthInterceptor implements GrpcInterceptor {
  readonly #oAuthService = inject(OAuthService);

  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    if (isMockAuth() === false) {
      const bearer = this.#oAuthService.getAccessToken()?.toString()?.trim();
      if (isNotEmptyString(bearer)) {
        request.requestMetadata.set('Authorization', `Bearer ${bearer}`);
        request.requestMetadata.set('IdToken', this.#oAuthService.getIdToken());
        request.requestMetadata.set('email', this.#oAuthService.getIdentityClaims()['email']);
      }
    }

    return next.handle(request);
  }
}
