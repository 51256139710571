import { isString } from '@camino-solutions/utils/typeguard';

export const availableLanguages = ['hu', 'en'] as const;
export type AvailableLanguages = (typeof availableLanguages)[number];

export function assertAvailableLanguages(value: unknown): asserts value is AvailableLanguages {
  if (
    isString(value) === false ||
    ([...availableLanguages] as string[]).includes(value) === false
  ) {
    throw new Error(`Not a AvailableLanguages: ${value}`);
  }
}
