import type { AppMenuItem } from '@camino-solutions/core/app/ui/app-menu';

export const menu: readonly AppMenuItem[] = Object.freeze([
  {
    label: 'Dashboard',
    icon: 'pi pi-chart-line',
    routerLink: ['/'],
    items: [
      {
        label: 'Charts',
        icon: 'pi pi-chart-line',
        routerLink: ['/'],
      },
    ],
  },
  {
    label: 'Company',
    icon: 'pi pi-building',
    items: [
      {
        label: 'Create',
        icon: 'pi pi-plus-circle',
        routerLink: ['/page-flow/company/create'],
      },
    ],
  },
  {
    label: 'Project',
    icon: 'pi pi-briefcase',
    items: [
      {
        label: 'Create',
        icon: 'pi pi-plus-circle',
        routerLink: ['/page-flow/project/create'],
      },
    ],
  },
  {
    label: 'Document',
    icon: 'pi pi-users',
    items: [
      {
        label: 'Create',
        icon: 'pi pi-plus-circle',
        routerLink: ['/page-flow/document/create'],
      },
    ],
  },
  // {
  //   label: 'Subscriptions',
  //   icon: 'pi pi-users',
  //   items: [
  //     {
  //       label: 'List',
  //       icon: 'pi pi-bars',
  //       // routerLink: ['/subscriptions/fixed', { outlets: { list: ['list'] } }],
  //       routerLink: ['/subscriptions'],
  //     },
  //     {
  //       label: 'Create a new Fixed fixed',
  //       icon: 'pi pi-user-plus',
  //       // routerLink: ['/subscriptions/fixed', { outlets: { details: ['new'] } }],
  //       routerLink: ['/subscriptions/fixed/new'],
  //     },
  //   ],
  // },
  // {
  //   label: 'Test',
  //   // accessRule: TestRule.instance,
  //   items: [
  //     {
  //       label: 'Test form',
  //       routerLink: ['/testform'],
  //       // accessRule: TestRule.instance,
  //     },
  //   ],
  // },
  // {
  //   label: 'Session panel',
  //   items: [
  //     {
  //       label: 'Debug',
  //       routerLink: ['/session-test-panel'],
  //     },
  //   ],
  // },
]);
