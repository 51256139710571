import { translocoConfig, TranslocoOptions } from '@jsverse/transloco';
import { environment } from '@camino-solutions/core/environment';
import { TranslocoHttpLoader } from './transloco-loader';
import { AvailableLanguages } from '../typing/languages';

export const translocoOptions: TranslocoOptions = {
  config: translocoConfig({
    availableLangs: ['hu', 'en'] satisfies AvailableLanguages[],
    defaultLang: 'hu' satisfies AvailableLanguages,
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: !environment.isAngularDevMode,
    flatten: {
      aot: !environment.isAngularDevMode,
    },
  }),
  loader: TranslocoHttpLoader,
};
