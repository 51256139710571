import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { appRoutes } from './app.routes';
import { provideRouter, withHashLocation, withInMemoryScrolling } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';
import { provideTransloco } from '@jsverse/transloco';
import { environment } from '@camino-solutions/core/environment';
import { AuthConfig, provideOAuthClient } from 'angular-oauth2-oidc';
import {
  authAppInitializerFactory,
  AuthService,
  AuthServiceProvider,
} from '@camino-solutions/core/auth/service';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { provideStore } from '@ngxs/store';
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin';
import { AppState } from '@camino-solutions/core/app/state';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MessageService } from 'primeng/api';
import { GrpcModule } from '@camino-solutions/core/grpc';
import {
  SessionService,
  sessionServiceInitializerFactory,
  SessionServiceProvider,
} from '@camino-solutions/core/session/service';
import { menu } from './config/menu.config';
import { MENU_TOKEN } from '@camino-solutions/core/app/ui/app-menu';
import { userAppInitializerFactory, UserService } from '@camino-solutions/core/user/service';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { translocoOptions } from '@camino-solutions/core/i18n';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: AuthConfig, useValue: environment.auth },
    {
      provide: APP_INITIALIZER,
      useFactory: authAppInitializerFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: userAppInitializerFactory,
      deps: [UserService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sessionServiceInitializerFactory,
      deps: [SessionService],
      multi: true,
    },
    //APP_BOOTSTRAP_LISTENER
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({ anchorScrolling: 'enabled' }),
      withHashLocation(),
      // withEnabledBlockingInitialNavigation()
      // withEnabledBlockingInitialNavigation()
      // withDebugTracing()
    ),
    provideHttpClient(/*withInterceptors([authInterceptor()])*/),
    provideAnimationsAsync(),
    // provideAuth(authConfig),
    provideOAuthClient(),
    provideStore(
      [AppState],
      { developmentMode: environment.isAngularDevMode, selectorOptions: {} },
      withNgxsLoggerPlugin({ logger: console, collapsed: false, disabled: true }),
      withNgxsReduxDevtoolsPlugin({ disabled: !environment.isAngularDevMode }),
      withNgxsStoragePlugin({
        keys: ['app.theme', 'app.menu', 'session.panelIsOpen'],
        namespace: 'cs',
      }),
      // withNgxsPreboot(() => {
      //   const pendingTasks = inject(ExperimentalPendingTasks);
      //   const actions$ = inject(Actions);
      //
      //   const actionToRemoveTaskFnMap = new Map<any, () => void>();
      //
      //   // Note that you don't have to unsubscribe from the actions stream in
      //   // this specific case, as we complete the actions subject when the root
      //   // view is destroyed. In server-side rendering, the root view is destroyed
      //   // immediately once the app stabilizes and its HTML is serialized.
      //   actions$.subscribe(ctx => {
      //     if (ctx.status === ActionStatus.Dispatched) {
      //       const removeTaskFn = pendingTasks.add();
      //       actionToRemoveTaskFnMap.set(ctx.action, removeTaskFn);
      //     } else {
      //       const removeTaskFn = actionToRemoveTaskFnMap.get(ctx.action);
      //       if (typeof removeTaskFn === 'function') {
      //         removeTaskFn();
      //         actionToRemoveTaskFnMap.delete(ctx.action);
      //       }
      //     }
      //   });
      // })
      // withNgxsStoragePlugin({ keys: [TODOS_STORAGE_KEY] })
    ),
    provideHttpClient(),
    provideTransloco(translocoOptions),
    MessageService,
    importProvidersFrom(GrpcModule),
    SessionServiceProvider,
    AuthServiceProvider,
    {
      provide: MENU_TOKEN,
      useValue: menu,
    },
  ],
};

//TODO playground csomagot csinalni es atrakni a test form-t
