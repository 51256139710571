import { inject, Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcDataEvent,
  type GrpcEvent,
  type GrpcMessage,
  type GrpcRequest,
} from '@ngx-grpc/common';
import {
  GRPC_LOGGER_SETTINGS,
  GrpcHandler,
  type GrpcInterceptor,
  type GrpcLoggerSettings,
} from '@ngx-grpc/core';
import { isObservable, Observable, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import type { DeepPartial, RequiredAll } from '@camino-solutions/utils/typing';

/**
 * Interceptor that implements logging of every request to the browser console
 *
 * Can be enabled / disabled by GRPC_LOGGER_ENABLED injection token
 */
@Injectable()
export class GrpcLoggerInterceptor implements GrpcInterceptor {
  private static requestId = 0;

  readonly #window = inject(DOCUMENT).defaultView;

  private clientDataStyle = 'color: #eb0edc;';
  private dataStyle = 'color: #5c7ced;';
  private errorStyle = 'color: #f00505;';
  private statusOkStyle = 'color: #0ffcf5;';

  private settings: RequiredAll<GrpcLoggerSettings>;

  constructor(
    @Optional()
    @Inject(GRPC_LOGGER_SETTINGS)
    settings: DeepPartial<GrpcLoggerSettings> = {},
  ) {
    this.settings = {
      enabled: settings.enabled ?? true,
      logClientSettings: settings.logClientSettings ?? true,
      logMetadata: settings.logMetadata ?? true,
      logStatusCodeOk: settings.logStatusCodeOk ?? false,
      requestMapper: settings.requestMapper ?? ((msg: GrpcMessage) => msg.toObject()),
      responseMapper: settings.responseMapper ?? ((msg: GrpcMessage) => msg.toObject()),
    };
  }

  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    if (this.settings.enabled) {
      const id = ++GrpcLoggerInterceptor.requestId;
      const start = Date.now();

      // check if client streaming, then push each value separately
      if (isObservable(request.requestData)) {
        request.requestData = request.requestData.pipe(
          tap(msg => {
            console.groupCollapsed(
              `%c#${id}: ${Date.now() - start}ms -> ${request.path}`,
              this.clientDataStyle,
            );
            console.log('%c>>', this.clientDataStyle, this.settings.requestMapper(msg));
            console.groupEnd();
          }),
        );
      }

      // handle unary calls and server streaming in the same manner
      return next.handle(request).pipe(
        tap(event => {
          const style =
            event instanceof GrpcDataEvent
              ? this.dataStyle
              : event.statusCode !== 0
                ? this.errorStyle
                : this.statusOkStyle;

          const openGroup = () =>
            console.groupCollapsed(`%c#${id}: ${Date.now() - start}ms -> ${request.path}`, style);

          const printSettings = () => {
            if (this.settings.logClientSettings) {
              console.log('%csc', style, request.client.getSettings());
            }
          };

          const printMetadata = () => {
            if (this.settings.logMetadata) {
              console.log('%c**', style, request.requestMetadata.toObject());
            }
          };

          const getRequestResult = () =>
            isObservable(request.requestData)
              ? '<see above>'
              : this.settings.requestMapper(request.requestData);

          const printRequest = () => console.log('%c>>', style, getRequestResult());

          const closeGroup = () => console.groupEnd();

          if (event instanceof GrpcDataEvent) {
            openGroup();
            printSettings();
            printRequest();
            printMetadata();
            const response = this.settings.responseMapper(event.data);
            console.log('%c<<', style, response);

            console.groupCollapsed('raw request');
            console.log(JSON.stringify(getRequestResult(), null, 2));
            console.groupEnd();

            console.groupCollapsed('raw response');
            console.log(JSON.stringify(response, null, 2));
            console.groupEnd();

            closeGroup();
          } else if (event.statusCode !== 0) {
            openGroup();
            printSettings();
            printRequest();
            printMetadata();
            console.log('%c<<', style, event);

            console.groupCollapsed('raw request');
            console.log(JSON.stringify(getRequestResult(), null, 2));
            console.groupEnd();

            console.groupCollapsed('raw response');
            console.log(JSON.stringify(event, null, 2));
            console.groupEnd();

            closeGroup();
          } else if (event.statusCode === 0 && this.settings.logStatusCodeOk) {
            openGroup();
            printSettings();
            printRequest();
            printMetadata();
            console.log('%c<<', style, event);

            console.groupCollapsed('raw request');
            console.log(JSON.stringify(getRequestResult(), null, 2));
            console.groupEnd();

            console.groupCollapsed('raw response');
            console.log(JSON.stringify(event, null, 2));
            console.groupEnd();

            closeGroup();
          }
        }),
      );
    }

    return next.handle(request);
  }
}
