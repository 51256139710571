import { NgModule } from '@angular/core';
import { GRPC_INTERCEPTORS, GrpcCoreModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import { GrpcMessage } from '@ngx-grpc/common';
import { GrpcAuthInterceptor } from './grpc-auth.interceptor';
import { ImprobableEngGrpcWebClientModule } from '@ngx-grpc/improbable-eng-grpc-web-client';
import { grpc } from '@improbable-eng/grpc-web';
import { GrpcLoggerModule } from './logger/grpc-logger.module';

const xhr = grpc.CrossBrowserHttpTransport({
  /*withCredentials: true */
});

@NgModule({
  imports: [
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot({
      // settings: { host: 'http://localhost:8080' },
      settings: { host: '' /*, withCredentials: true */ },
    }),
    GrpcLoggerModule.forRoot({
      settings: {
        requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
      },
    }),
    ImprobableEngGrpcWebClientModule.forRoot({
      settings: {
        host: '',
        transport: {
          unary: xhr,
          serverStream: xhr,
          // We not use clientStream and bidiStream
          clientStream: xhr,
          bidiStream: xhr,
        },
      },
    }),
  ],
  providers: [
    {
      provide: GRPC_INTERCEPTORS,
      multi: true,
      useClass: GrpcAuthInterceptor,
    },
    // {
    //   provide: GRPC_IN,
    //   useClass: GrpcLoggerInterceptor,
    //   useFactory: () => {
    //     return new GrpcCopyLoggerInterceptor(
    //       inject(GRPC_LOGGER_SETTINGS, { optional: true }) as DeepPartial<GrpcLoggerSettings>,
    //     );
    //   },
    // },
  ],
  exports: [GrpcCoreModule, GrpcWebClientModule, ImprobableEngGrpcWebClientModule],
})
export class GrpcModule {}
